import React, { useContext, useEffect, useState } from "react";
import Typing from "react-typing-effect";
import SocialLinks from "../../components/SocialLinks/SocialLinks";
import WebsiteLinks from "../../components/WebsiteLinks/WebsiteLinks";
import "./Linktree.scss";
import Website from "../website/Website";
import profileImg from "../../assets/image/profile.jpg";
import WebsiteContext from "../../context/WebsiteContext";
import Loader from "../../components/Loader/Loader";

const Linktree = () => {
  const { isPortfolioVisible, setIsPortfolioVisible } =
    useContext(WebsiteContext);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <div className="container">
          <div className="linktree-section">
            <div className="main">
              <div className="heading">
                <img
                  className="profile-img"
                  alt="profile picture"
                  src={profileImg}
                />
                <h1 className="page-title">निर / Nir</h1>
                <div className="page-bioline">
                  किताब , सिनेमा {/* र एल्बम */}
                  <Typing
                    speed={100}
                    eraseSpeed={10}
                    typingDelay={10}
                    // eraseDelay={00}
                    text={[" र एल्बम। ", " , एल्बम र म। "]}
                  />
                </div>
                <SocialLinks />
              </div>
              <WebsiteLinks />
            </div>
          </div>

          {/* The portfolio slides over the Linktree */}
          <div
            className={`portfolio-section ${
              isPortfolioVisible ? "visible" : ""
            }`}
          >
            <div className="portfolio-content">
              <Website />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Linktree;
