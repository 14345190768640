const activities = {
  books: [
    { date: "2024-12-12", title: "Mr Salary" },
    { date: "2024-11-11", title: "Chena Harayeko Manche" },
    { date: "2024-10-06", title: "The Metamorphosis" },
    { date: "2024-09-30", title: "Nepali Art,Thoughts & Musings" },
    { date: "2024-08-05", title: "Thought Power" },
    { date: "2024-07-27", title: "To Kill A Mockingbird" },
    { date: "2024-07-05", title: "Lost In Translation" },
    { date: "2024-06-11", title: "The Power of Now" },
    { date: "2024-05-30", title: "2 States" },
    { date: "2024-04-13", title: "Lessons From Plant" },
    { date: "2024-03-01", title: "Don't Believe Everything You Think" },
    { date: "2024-03-23", title: "Yellow Face" },
    { date: "2024-03-31", title: "The War of Art" },
    { date: "2024-02-15", title: "First Person Singular" },
    { date: "2024-02-20", title: "Seto Dharti" },
    {
      date: "2024-02-21",
      title: "Ikigai: The Japanese Secret to a Long and Happy Life",
    },
    { date: "2024-01-19", title: "Bhupi, A Daughter's Memoir" },
    { date: "2024-01-20", title: "Digital Minimalism" },
    { date: "2024-01-22", title: "Blue Mimosa" },
  ],
  movies: [
    { date: "2024-12-10", title: "The 100" },
    { date: "2024-11-04", title: "Bhool Bhulaiyaa" },
    { date: "2024-11-05", title: "Bhool Bhulaiyaa 2" },
    { date: "2024-11-10", title: "The 100" },
    { date: "2024-11-16", title: "Acharya" },
    { date: "2024-11-26", title: "Touch" },

    { date: "2024-10-09", title: "How To Make Millions Before Grandma Dies" },
    { date: "2024-10-11", title: "Blackmail" },
    { date: "2024-10-16", title: "Taaza Khabar" },
    { date: "2024-10-21", title: "The Platform" },
    { date: "2024-10-31", title: "The Platform 2" },

    { date: "2024-09-01", title: "Sacred Games" },
    { date: "2024-09-02", title: "IC841:The Kandahar Hijack" },
    { date: "2024-09-03", title: "Gandu" },
    { date: "2024-09-03", title: "The Lift Boy" },
    { date: "2024-09-05", title: "The Tattoist of Aushwitz" },
    { date: "2024-09-10", title: "Greatest Events of WWII" },
    { date: "2024-09-13", title: "Gangs of Wasseypur" },
    { date: "2024-09-20", title: "Mirzapur" },
    { date: "2024-09-30", title: "Behind Her Eyes" },

    { date: "2024-08-01", title: "Victoria & Abdul" },
    { date: "2024-08-05", title: "Kung FU Hustle" },
    { date: "2024-08-08", title: "Modern Masters:SS Rajamouli" },
    { date: "2024-08-09", title: "18x2 Beyond Youthful Days" },
    { date: "2024-08-10", title: "Kingsmen:The Secret Service" },
    { date: "2024-08-10", title: "Kingsmen:The Golden Circle" },
    { date: "2024-08-11", title: "Tamasha" },
    { date: "2024-08-12", title: "Secret of Neanderthals" },
    { date: "2024-08-12", title: "12 Mighty Orphans" },
    { date: "2024-08-22", title: "Bhaag Milkha Bhag" },
    { date: "2024-08-23", title: "Kalki" },
    { date: "2024-08-27", title: "Shoplifters" },

    { date: "2024-07-02", title: "Little Forest" },
    { date: "2024-07-04", title: "Karwaan" },
    { date: "2024-07-09", title: "Lost In Translation" },
    { date: "2024-07-17", title: "Slumdog Millionaire" },
    { date: "2024-07-20", title: "Lion" },
    { date: "2024-07-24", title: "The Darjeeling Limited" },
    { date: "2024-07-25", title: "Jiro Dreams of Sushi" },
    { date: "2024-07-26", title: "Mr & Mrs Mahi" },
    { date: "2024-07-28", title: "Minari" },
    { date: "2024-07-29", title: "Rock On 2" },
    { date: "2024-07-30", title: "Hotel Mumbai" },
    { date: "2024-07-31", title: "Malcolm X" },
    { date: "2024-07-31", title: "YOLO" },
    { date: "2024-06-02", title: "Panchayat S03" },
    { date: "2024-06-13", title: "Monkey Man" },
    { date: "2024-06-15", title: "Shaitaan" },
    { date: "2024-06-17", title: "Bramayugam" },
    { date: "2024-06-20", title: "Anime Supremacy" },
    { date: "2024-06-21", title: "Rush Hour 3" },
    { date: "2024-06-23", title: "Monster" },
    { date: "2024-06-26", title: "Soulmate" },
    { date: "2024-06-29", title: "8 A.M. Metro" },
    { date: "2024-05-08", title: "Arrival" },
    { date: "2024-05-17", title: "Laapata Ladies" },
    { date: "2024-05-21", title: "Past Lives" },
    { date: "2024-05-23", title: "Piku" },
    { date: "2024-05-25", title: "Perfect Days" },
    { date: "2024-05-27", title: "The Sea Beast" },
    { date: "2024-05-27", title: "Coco" },
    { date: "2024-05-28", title: "Portrait Of A Lady On Fire" },
    { date: "2024-05-30", title: "My Neighbour Totoro" },
    { date: "2024-04-06", title: "My Liberation Notes" },
    { date: "2024-04-13", title: "Parasyte: The Grey" },
    { date: "2024-04-18", title: "Hunger" },
    { date: "2024-04-20", title: "The Call" },
    { date: "2024-03-01", title: "Nowhere" },
    { date: "2024-03-02", title: "Lunana, A Yak In The Classroom" },
    { date: "2024-03-02", title: "Sparking Joy with Marie Kondo" },
    { date: "2024-03-07", title: "A Sun" },
    { date: "2024-03-10", title: "The Present" },
    { date: "2024-03-16", title: "House Of Ninjas" },
    { date: "2024-03-16", title: "The Pig, The Snake and The Pigeon" },
    { date: "2024-03-19", title: "Backstreet Rookie" },
    { date: "2024-03-24", title: "Anatomy Of A Fall" },
    { date: "2024-03-25", title: "You Are What You Eat, A Twin Experiment" },
    { date: "2024-03-27", title: "Wednesday" },
    { date: "2024-03-29", title: "Elemental" },
    { date: "2024-02-02", title: "Forgotten Love" },
    { date: "2024-02-03", title: "Okja" },
    { date: "2024-02-04", title: "Cast Away" },
    { date: "2024-02-06", title: "Awakenings" },
    { date: "2024-02-07", title: "A Death In The Gunj" },
    { date: "2024-02-08", title: "Ak vs Ak" },
    { date: "2024-02-08", title: "The Song of Scorpions" },
    { date: "2024-02-10", title: "Madness In The Desert" },
    { date: "2024-02-10", title: "Nyad" },
    { date: "2024-02-13", title: "Anaconda" },
    { date: "2024-02-14", title: "Nimona" },
    { date: "2024-02-15", title: "Main Hun Na" },
    { date: "2024-02-15", title: "Dunki" },
    { date: "2024-02-17", title: "Einstein and The Bomb" },
    { date: "2024-02-24", title: "Avatar, The Last Airbender" },
    { date: "2024-01-01", title: "Beef" },
    { date: "2024-01-04", title: "12th Fail" },
    { date: "2024-01-05", title: "Captain of the World" },
    { date: "2024-01-07", title: "Doob; No Bed of Roses" },
    { date: "2024-01-07", title: "Top Secret UFO Project Declassified" },
    { date: "2024-01-09", title: "Soul" },
    { date: "2024-01-11", title: "Maqbool" },
    { date: "2024-01-12", title: "Black Holes, The Edge of All We Know" },
    { date: "2024-01-13", title: "Budhia Singh, Born To Run" },
    { date: "2024-01-13", title: "If Anything Happens I Love You" },
    { date: "2024-01-15", title: "Super Deluxe" },
    { date: "2024-01-16", title: "Summer Strike" },
    { date: "2024-01-20", title: "Killer Soup" },
    { date: "2024-01-25", title: "Society of Snow" },
    { date: "2024-01-29", title: "Animal" },
    { date: "2024-01-30", title: "The Last Days" },
  ],
  albums: [
    { date: "2024-01-01", title: "O", artist: "Damien Rice" },
    { date: "2024-01-01", title: "Voyage", artist: "Abba" },
    { date: "2024-02-01", title: "For Emma, Forever Ago", artist: "Bon Iver" },
    { date: "2024-02-01", title: "Rumours", artist: "Fleetwood Mac" },
    { date: "2024-03-01", title: "Psychopomp", artist: "Japanese Breakfast" },
    { date: "2024-03-01", title: "Jubliee", artist: "Japanese Breakfast" },
    {
      date: "2024-04-01",
      title: "Soft Sounds from Another Planet",
      artist: "Japanese Breakfast",
    },
    { date: "2024-04-01", title: "9", artist: "Damien Rice" },
    {
      date: "2024-05-01",
      title: "My Favorite Faded Fantasy",
      artist: "Damien Rice",
    },
    { date: "2024-05-01", title: "Harry's House", artist: "Harry Styles" },
    { date: "2024-06-01", title: "Fine Line", artist: "Harry Styles" },
    { date: "2024-06-01", title: "Continuum", artist: "John Mayer" },
    { date: "2024-07-21", title: "OK Computer", artist: "Radiohead" },
    { date: "2024-08-01", title: "The Visitors", artist: "Abba" },
    { date: "2024-09-01", title: "Born And Raised", artist: "John Mayer" },
    {
      date: "2024-10-01",
      title: "The Search For Everything",
      artist: "John Mayer",
    },
    {
      date: "2024-11-01",
      title: "Aandhii,Ityaadi",
      artist: "Bartika Eam Rai",
    },
    {
      date: "2024-12-01",
      title: "Lukamari",
      artist: "Yugal Gurung",
    },
     {
      date: "2024-12-01",
      title: "Shankar Family & Friends",
      artist: "Ravi Shankar",
    },
  ],
  projects: [
    {
      name: "Many Consultancy",
      link: "https://manyconsultancy.com/",
      // videoUrl: "",
      imageUrl: "./images/many-consultancy.png",
    },
    {
      name: "Nilisha Website",
      link: "https://www.nilishasingh.com.np",
      videoUrl: "",
      imageUrl: "./images/nilisha-website.png",
      // videoUrl: "https://www.youtube.com/embed/cR8T85fWsX4?autoplay=1",
    },
    {
      name: "Self Ordering Menu For Restaurant",
      link: "https://www.youtube.com/watch?v=cR8T85fWsX4",
      videoUrl: "https://www.youtube.com/embed/cR8T85fWsX4?autoplay=1",
    },
    {
      name: "Personal Website Clone",
      link: "https://elenagurungportfolio.netlify.app/",
      videoUrl: "https://www.youtube.com/embed/gvdqDLtCNtE?si=PDlstwgPJkzSagw5",
    },
  ],
  timelineData: [
    {
      title: "Stockinerary",
      link: "https://stockinerary.com/",
      date: "2024 - present",
      description: "Working as a Frontend Developer.",
    },
    {
      title: "Many Consultancy",
      link: "https://manyconsultancy.com/",
      date: "2024 - present",
      description: "Working as a Frontend Developer.",
    },
    {
      title: "eKagajPatra Pvt. Ltd.",
      link: "https://ekagajpatra.com/",
      date: "2024 Apr - Sept",
      description: "Worked as a Frontend Developer Intern.",
    },
    {
      title: "Home Tutor",
      link: "",
      date: "2021 - 2022",
      description:
        "Taught student of grade 6th and 10th for their examination.",
    },
    {
      title: "Knitwear Designer",
      link: "",
      date: "2020 - 2021",
      description: "Designed and knitted for @woolfactory.",
    },
    {
      title: "Shanker Dev Campus",
      link: "https://www.shankerdevcampus.edu.np/",
      date: "2019 - 2024",
      description: "Undergrad of Information Management.",
    },
    {
      title: "Omega Int'l College",
      link: "https://www.omegacollege.edu.np/",
      date: "2017 - 2018",
      description: "Studied Management majoring in Computer Science in +2.",
    },
    {
      title: "Westwing School",
      link: "https://www.westwingschool.edu.np/",
      date: "2003 - 2017",
      description: "Gave SEE examination from Westwing School.",
    },
  ],
};

export default activities;
